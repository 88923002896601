import React, { useEffect, useState, useMemo, useLayoutEffect } from "react";
import { debounce } from "lodash";
import Loader from "./components/Loader/Loader";
import "./App.css";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import "moment/locale/ar";
import { connect } from "react-redux";
import { requestNotificationPermission, onMessageListener } from './firebase';
import { actions as OrganizationActions } from "./redux/actions/OrganizationActions";
import { actions as TranslationActions } from "./redux/actions/TranslationActions";
import { actions as DesignsActions } from "./redux/actions/DesignsActions";
import { actions as AppSourceActions } from "./redux/actions/AppSourceActions";
import { actions as AffiliateLinksActions } from "./redux/actions/AffiliateLinksActions";
import { actions as DeliveryMethodActions } from "./redux/actions/DeliveryMethodActions";
import { actions as ProductsActions } from "./redux/actions/ProductsActions";
import { actions as RestaurantsActions } from "./redux/actions/RestaurantsActions";
import { actions as NotificationsActions } from "./redux/actions/NotificationsActions";
import { actions as UserActions } from "./redux/actions/UserActions";
import { actions as AddressesActions } from "./redux/actions/AddressesActions";
import { actions as LoyaltyActions } from "./redux/actions/LoyaltyActions";
import { actions as CartActions } from "./redux/actions/CartActions";
import { actions as DiscountsActions } from "./redux/actions/DiscountsActions";
import { actions as PromotionsActions } from "./redux/actions/PromotionsActions";
import { actions as MediaActions } from "./redux/actions/MediaActions";
import { actions as MessagesActions } from "./redux/actions/MessagesActions";
import { actions as FavoritesActions } from "./redux/actions/FavoritesActions";
import { actions as GlobalActions } from "./redux/actions/GlobalActions";
import { actions as PopularItemsActions } from "./redux/actions/PopularItemsActions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RoutesContainer from "./AppComponents/RoutesContainer";
import { shouldShowSplash } from "./utils/splashScreen";
import { toLatinNumbers } from "./Common";
import { checkQRCartExpiration, cartDraftProceed, updateCartUsedPoints } from "./utils/cart";
import { getIncomingOrigin } from "./utils/incomingOrigin";
import { setDesignDefaultValues, setFontValues, setLoginDesignValues, setDesignValues, setMainDesignValues, updateMetaImage } from "./utils/design";
import { handleNotification } from "./utils/notification";
import { notify } from "./utils/notify";
import { sendAffiliateLinkReference } from "./config/config";
import { setupData } from "./data/designSetup";

import { HelmetProvider } from "react-helmet-async";

const App = ({
  userHasInteracted,
  lastAppOpenTime,
  organization,
  supportedLanguages,
  translationLoading,
  language,
  selectedTheme,
  designs,
  loginDesignSetup,
  splashScreen,
  currentRestaurantDesign,
  getDesignsLoading,
  mainDesign,
  token,
  userPoints,
  fcmToken,
  getUnreadMessagesLoading,
  reference,
  getRestaurantsLoading,
  getFavoritesLoading,
  menusSelectedRestaurant,
  selectedMenu,
  menus,
  selectedRestaurant,
  appSelectedRestaurant,
  selectedRestaurantAvailableBranches,
  logo,
  deliveryMethod,
  deliverySelectedBranch,
  userSelectedBranch,
  dineInSelectedBranch,
  paySelectedBranch,
  QRSelectedBranch,
  cartDrafts,
  cartBranch,
  cartDeliveryMethod,
  cart,
  QRCart,
  cartPointsData,
  products,
  appSource,
  clearCart,
  hasDelivery,
  hasPickup,
  hasDinein,
  currentRestaurantModeIconsStyle,
  getRestaurantBranchModulesLoading,

  //actions
  setLastAppOpenTime,
  getModules,
  setOrganization,
  getTranslation,
  getDesigns,
  setReference,
  clearAllRestaurantsData,
  resetAppSource,
  updateCurrentRestaurantThemeData,
  resetDesigns,
  setSelectedTheme,
  setAppSource,
  getMe,
  setFcmToken,
  registerDevice,
  getLoyaltyInfo,
  updateCartDetail,
  cartDraftUseStart,
  cartDraftUseEnd,
  setGotUsedMenus,
  resetMenus,
  updatePointsUsed,
  removePointsUsed,
  resetPointsData,
  changeDeliveryMethod,
  resetUserCurrentLocation,
  resetNearestDeliveryBranch,
  setNoSelectedLocationMessagePoppedUp,
  setShowSelectedLocationAnimation,
  setFarFromSelectedLocationMessageConfirmed,
  setHandledCurrentLocation,
  clearFilters,
  getAllRestaurants,
  clearAllDiscountsData,
  clearAllPromotionsData,
  addToCart,
  setCartDetail,
  setCurrentAddress,
  setUserSelectedBranch,
  getRestaurantBranchModules,
  getFavorites,
  clearAllMediaData,
  getUnreadMessages,
  getMessages,
  clearAllMessagesData,
  clearAllFavoritesData,
  clearLoading,
  clearAllData,
  clearAllPopularItemsData,
  restoBranchHasLoyalty,
  setUserHasInteracted
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [importedLocales, setImportedLocales] = useState(new Set());
  const [showSplash, setShowSplash] = useState(true);
  const [loginSkipped, setLoginSkipped] = useState(false);
  const [notification, setNotification] = useState(null);

  const languageMapping = {
    english: "en",
    arabic: "ar",
    french: "fr",
  };

  const handleCartDraft = (cartData) => {
    if (cartData?.cartDeliveryMethod === "delivery") {
      changeDeliveryMethod("delivery");
      updateCartDetail("cartDeliveryMethod", "delivery");
      cartDraftProceed(cartData?.cart, appSelectedRestaurant, cartDraftUseStart, cartDraftUseEnd, addToCart);
    } else if (cartData?.cartDeliveryMethod === "pickUp") {
      const branchId = cartData?.cartBranch;
      setUserSelectedBranch(branchId);
      changeDeliveryMethod("pickUp");
      setCartDetail(selectedRestaurant, branchId, "pickUp");
      cartDraftProceed(cartData?.cart, appSelectedRestaurant, cartDraftUseStart, cartDraftUseEnd, addToCart);
    }
  };
  const handleNotificationClick = (url) => {
    console.log('url:', url);
    if (process.env.NODE_ENV === "development") {
      // Prepend localhost URL in development
      window.location.href = `http://localhost:3000${url}`;
    } else {
      // Navigate to the full URL in production
      window.location.href = url;
    }
  };


  onMessageListener()
    .then((payload) => {
      // getNotifications()
      setNotification(payload)
      // console.log('payload', payload)
    })
    .catch((err) => console.log('failed: ', err));

  useEffect(() => {
    if (location?.pathname) {
      const pathSegments = location.pathname.split("/");
  
      if (!pathSegments.includes("media") && !userHasInteracted) {
          setUserHasInteracted(true);
      }
    }
  }, [location?.pathname, userHasInteracted])

  useEffect(() => {
      setUserHasInteracted(false);
  }, [])

  useEffect(() => {
    if (token) {
      requestNotificationPermission()
        .then((token) => {
          // console.log('FCM Token:', token);
          setFcmToken(token);
        })
        .catch((error) => console.error("Error: ", error));
    }
  }, [token]);

  // Register device with FCM token
  useEffect(() => {
    if (fcmToken && fcmToken !== "") {
      registerDevice(fcmToken);
    }
  }, [fcmToken]);

  useEffect(() => {
    if (notification) {
      handleNotification(notification, selectedRestaurant, getMessages, getUnreadMessages, handleNotificationClick, currentRestaurantModeIconsStyle, location);
    }
  }, [notification]);

  useEffect(() => {
    if (selectedRestaurant && token) {
      getUnreadMessages(selectedRestaurant);
    }
  }, [selectedRestaurant, token])

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Function that will be called before refresh
      clearLoading()
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    //reset app source
    // setAppSource("");
    resetAppSource();
    //clear all restaurants data
    clearAllRestaurantsData();
  }, [])


  useEffect(() => {
    clearAllFavoritesData();
  }, [deliverySelectedBranch, userSelectedBranch, selectedRestaurant, appSource, dineInSelectedBranch, paySelectedBranch, QRSelectedBranch])

  useEffect(() => {
    clearAllPopularItemsData();
  }, [selectedRestaurant, appSource])

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setShowSplash(false); // Hide the splash screen after 7 seconds

  //     setLastAppOpenTime();
  //   }, 3000);

  //   return () => clearTimeout(timeout);
  // }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    const handleMediaChange = (e) => {
      setShowSplash(e.matches);
    };

    handleMediaChange(mediaQuery);

    // Listen for changes
    mediaQuery.addEventListener("change", handleMediaChange);

    // Set timeout to hide the splash screen
    const timeout = setTimeout(() => {
      setShowSplash(false); // Hide the splash screen after 3 seconds
      setLastAppOpenTime();
    }, 3000);

    // Clean up event listener and timeout on component unmount
    return () => {
      mediaQuery.removeEventListener("change", handleMediaChange);
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    //clear media
    clearAllMediaData();

    //reset got used menus on app load
    setGotUsedMenus(false);
  }, [])

  useEffect(() => {
    if (appSource === "APP" && deliverySelectedBranch && !cartBranch && cart?.length > 0 && cartDeliveryMethod === "delivery") {
      updateCartDetail("cartBranch", deliverySelectedBranch);
    }
  }, [deliverySelectedBranch, cart, cartDrafts])

  useEffect(() => {
    if (appSource === "APP" && cart?.length === 0) {
      //check if there is a cart draft for the selected restaurant
      if (cartDrafts?.length > 0) {
        const cartDraft = cartDrafts?.find((draft) => {
          return draft?.ruuid === appSelectedRestaurant;
        })
        if (cartDraft && !cartDraft?.isUsed) {
          //use the draft
          handleCartDraft(cartDraft?.cart);
        }
      }
    }
  }, [cartDrafts, appSelectedRestaurant, appSource, products])

  useEffect(() => {
    if (language && currentRestaurantDesign) {
      setDesignDefaultValues(language, currentRestaurantDesign, selectedTheme, setupData);
    }
  }, [language, currentRestaurantDesign, selectedTheme]);

  useEffect(() => {
    // if (language && mainDesign) {
    if (language && !getDesignsLoading) {
      setFontValues(mainDesign, setupData, currentRestaurantDesign, selectedTheme, language);
    }
  }, [language, mainDesign, selectedTheme]);

  useEffect(() => {
    const loadLocales = async () => {
      if (supportedLanguages?.length > 0) {
        const importPromises = supportedLanguages?.map((lang) => {
          if (lang?.name !== 'english' && !importedLocales.has(languageMapping[lang?.name])) {
            return import(`moment/locale/${languageMapping[lang?.name]}`).then(() => {
              setImportedLocales((prev) => new Set(prev).add(languageMapping[lang?.name]));
            });
          }
          return Promise.resolve();
        });

        await Promise.all(importPromises);

        if (language === 'arabic') {
          moment.locale('ar');
          moment.updateLocale('ar', {
            postformat: (string) => {
              return string.replace(/\d/g, (c) => toLatinNumbers(c));
            },
          });
        } else if (language === 'english') {
          moment.locale('en');
        } else if (language === 'french') {
          moment.locale('fr');
        }
      }
    };

    loadLocales();
  }, [supportedLanguages, language]);

  useLayoutEffect(() => {
    document.body.setAttribute("dir", language === "arabic" ? "rtl" : "ltr");
    document.body.classList.toggle("arabic", language === "rtl");
  }, [language]);

  useEffect(() => {
    //get translation;
    if (!getRestaurantsLoading) {
      getTranslation();

      //get modules
      const onError = (errorMessage) => {
        notify.error(errorMessage);
      };
      getModules(onError);

      //get designs
      getDesigns();
    }
  }, [getRestaurantsLoading]);

  useEffect(() => {
    //get loyalty info
    if (selectedRestaurant && appSource) {
      getLoyaltyInfo(selectedRestaurant, appSource);
    }
  }, [appSource, selectedRestaurant])

  useEffect(() => {
    if (!getDesignsLoading) {
      setLoginDesignValues(loginDesignSetup);
    }
  }, [getDesignsLoading, loginDesignSetup])

  useEffect(() => {
    if (currentRestaurantDesign && currentRestaurantDesign?.autoMode) {
      const hasMoreThanOneMode = designs?.find((design) => {
        return design?.restaurantUuid === selectedRestaurant
      })?.designSetup?.length > 1;
      if (hasMoreThanOneMode) {
        const preferredDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
        if (preferredDark) {
          setSelectedTheme('dark-mode');
        }
        else {
          setSelectedTheme('light-mode');
        }
      }
    }
  }, [selectedRestaurant, designs, currentRestaurantDesign]);

  useEffect(() => {
    if (selectedRestaurant && designs?.length > 0) {
      updateCurrentRestaurantThemeData(selectedRestaurant, selectedTheme)
    }
  }, [selectedRestaurant, designs, selectedTheme])

  useEffect(() => {
    setDesignValues(currentRestaurantDesign, selectedTheme, setupData, language);
  }, [currentRestaurantDesign, selectedTheme])

  useEffect(() => {
    setMainDesignValues(mainDesign, selectedTheme, setupData);
  }, [mainDesign, selectedTheme])

  useEffect(() => {
    // Check if the pathname has a query parameter shared=true
    const queryParams = new URLSearchParams(location.search);
    const sharedParam = queryParams.get('shared');

    if ((!token || token === "" || token === null) && !sharedParam && appSource === "app") {
      navigate("/login");
    }
    if (token) {
      getMe();
    }
  }, [token, appSource]);

  useEffect(() => {
    if (logo) {
      updateMetaImage(logo);
    }
  }, [logo]);

  useEffect(() => {
    if (appSource) {
      const pathSegments = location.pathname.split("/");
      const firstPartOfPathname = pathSegments[1];

      if ((appSource !== "APP" && appSource?.toLowerCase() === firstPartOfPathname?.toLowerCase()) || appSource === "APP") {
        const key = appSource === "dine-in" ? "tableOrdering" : appSource === "pay" ? "check-menu-and-pay" : appSource === "QR" ? "qrMenu" : "onlineDelivery";
        if (key) {
        getAllRestaurants(key);
        }
      }
    }
  }, [appSource]);

  
  useEffect(() => {
    if (location.pathname?.includes("checkout")) {
      const pathSegments = location.pathname.split("/");
      const firstPartOfPathname = pathSegments[1];

      if ((appSource !== "APP" && appSource?.toLowerCase() === firstPartOfPathname?.toLowerCase()) || appSource === "APP") {
        const key = appSource === "dine-in" ? "tableOrdering" : appSource === "pay" ? "check-menu-and-pay" : appSource === "QR" ? "qrMenu" : "onlineDelivery";
        if (key) {
        getAllRestaurants(key);
        }
      }
    }
  }, [location.pathname]);

  const fetchFavoritesFunction = () => {
    if (appSource && token && selectedRestaurant && selectedRestaurantAvailableBranches && selectedRestaurantAvailableBranches?.length > 0) {
      const branch = appSource === "dine-in" ? dineInSelectedBranch :
        appSource === "QR" ? QRSelectedBranch :
          appSource === "pay" ? paySelectedBranch :
            (deliveryMethod === "delivery" ? deliverySelectedBranch : userSelectedBranch);

      const branchAvailable = selectedRestaurantAvailableBranches?.find((br) => {
        return br?.uuid === branch
      })

      if (branch && branchAvailable) {
        getFavorites(selectedRestaurant, appSource);
      }
    }
  };

  const memoizedFetchFavorites = useMemo(() => debounce(fetchFavoritesFunction, 500), [token, selectedRestaurant, dineInSelectedBranch, QRSelectedBranch, paySelectedBranch, deliveryMethod, deliverySelectedBranch, userSelectedBranch, appSource, selectedRestaurantAvailableBranches]);

  useEffect(() => {
    memoizedFetchFavorites();

    return () => memoizedFetchFavorites.cancel();
  }, [memoizedFetchFavorites]);

  //get resto/branch modules
  const fetchRestoBranchModulesFunction = () => {
    if (appSource && selectedRestaurant && selectedRestaurantAvailableBranches && selectedRestaurantAvailableBranches?.length > 0) {
      const branch = appSource === "dine-in" ? dineInSelectedBranch :
        appSource === "QR" ? QRSelectedBranch :
          appSource === "pay" ? paySelectedBranch :
            (deliveryMethod === "delivery" ? deliverySelectedBranch : userSelectedBranch);

      const branchAvailable = selectedRestaurantAvailableBranches?.find((br) => {
        return br?.uuid === branch
      })

      if (branch && branchAvailable) {
        getRestaurantBranchModules(selectedRestaurant, branch, appSource);
      }
    }
  };

  const memoizedFetchRestaurantBranchModules = useMemo(() => debounce(fetchRestoBranchModulesFunction, 500), [selectedRestaurant, dineInSelectedBranch, QRSelectedBranch, paySelectedBranch, deliveryMethod, deliverySelectedBranch, userSelectedBranch, appSource, selectedRestaurantAvailableBranches]);

  useEffect(() => {
    memoizedFetchRestaurantBranchModules();

    return () => memoizedFetchRestaurantBranchModules.cancel();
  }, [memoizedFetchRestaurantBranchModules]);

  useEffect(() => {
    const url = location.pathname;
    const newOrigin = getIncomingOrigin();
    setOrganization(newOrigin, organization);

    //check if url has a reference
    const params = new URLSearchParams(location.search);
    const ref = params.get('ref');

    if (ref) {
      //store ref, then remove it from the url
      setReference(ref);
      params.delete("ref");
      navigate({ search: params.toString() }, { replace: true });
      //send ref
      sendAffiliateLinkReference(ref);
    }
  }, [location]);

  useEffect(() => {
    if (location.pathname.startsWith("/qr")) {
      setAppSource("QR");
      changeDeliveryMethod("dineIn");
    } else if (location.pathname.startsWith("/dine-in")) {
      setAppSource("dine-in");
      changeDeliveryMethod("dineIn");
    } else if (location.pathname.startsWith("/pay")) {
      setAppSource("pay");
      changeDeliveryMethod("dineIn");
    } else {
      setAppSource("APP");
      if (!deliveryMethod || deliveryMethod === "dineIn") {
        changeDeliveryMethod("delivery");
      }
    }


    if (selectedRestaurant && ((selectedMenu && !menus?.find((menu) => { return menu?.uuid === selectedMenu })) || menusSelectedRestaurant !== selectedRestaurant)) {
      resetMenus(appSource);
    }

    return () => {
      setAppSource("APP");
    };
  }, [appSource, selectedRestaurant]);

  useEffect(() => {
    if (appSource === "QR" && QRCart?.length > 0) checkQRCartExpiration(QRCart, appSource, clearCart, clearFilters);
  }, [appSource, QRCart]);

  useEffect(() => {
    if (!appSource || appSource === "") {
      return;
    }
    //if qr, set prices to dine in
    // if (appSource === "QR" || appSource === "dine-in" || appSource === "pay") {
    //   changeDeliveryMethod(hasDinein ? "dineIn" : "");
    //   return;
    // }

    if (appSource !== "QR" && appSource !== "dine-in" && appSource !== "pay" && selectedRestaurantAvailableBranches?.length > 0) {
      if (deliveryMethod === "") {
        changeDeliveryMethod(
          hasDelivery
            ? "delivery"
            : hasPickup
              ? "pickUp"
              : hasDinein
                ? "dineIn"
                : ""
        );
        return;
      }
      if (deliveryMethod === "dineIn" && (hasDelivery || hasPickup)) {
        changeDeliveryMethod(hasDelivery ? "delivery" : "pickUp");
        return;
      }
      if (deliveryMethod === "delivery" && !hasDelivery) {
        changeDeliveryMethod(hasPickup ? "pickUp" : hasDinein ? "dineIn" : "");
        return;
      }
      if (deliveryMethod === "pickUp" && !hasPickup) {
        changeDeliveryMethod(
          hasDelivery ? "delivery" : hasDinein ? "dineIn" : ""
        );
        return;
      }
      if (deliveryMethod === "dineIn" && !hasDinein) {
        changeDeliveryMethod(
          hasDelivery ? "delivery" : hasPickup ? "pickUp" : ""
        );
      }
    }
  }, [hasDelivery, hasPickup, hasDinein, appSource, selectedRestaurant, selectedRestaurantAvailableBranches]);

  useEffect(() => {
    resetNearestDeliveryBranch();
  }, [selectedRestaurant]);

  useEffect(() => {
    setNoSelectedLocationMessagePoppedUp(false);
    setFarFromSelectedLocationMessageConfirmed(false);
    setShowSelectedLocationAnimation(true);
    resetUserCurrentLocation();
    setHandledCurrentLocation(false);
    resetDesigns();
  }, []);

  useEffect(() => {
    //this useEffect is to update points selected by user, if any changes on db, or branch change
    if (!cart || cart?.length === 0 || products?.length === 0) {
      return;
    }
    //else, get cart details
    updateCartUsedPoints(cart, products, deliveryMethod, cartDeliveryMethod, cartBranch, dineInSelectedBranch, paySelectedBranch, QRSelectedBranch, appSource, cartPointsData, token, userPoints, restoBranchHasLoyalty, resetPointsData, removePointsUsed, updatePointsUsed);

  }, [cart, token, restoBranchHasLoyalty, products])

  if (translationLoading || getDesignsLoading || (getRestaurantsLoading)) {
    return <Loader />
    // return showSplash && splashScreen?.image !== "" ? <SplashScreen /> : <Loader />;
  }

  return (
    // <HelmetProvider>
    <div
      className="App"
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <ToastContainer />
      <RoutesContainer showSplash={showSplash} />
    </div>
    // </HelmetProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    userHasInteracted: state.media.userHasInteracted,
    lastAppOpenTime: state.designs.lastAppOpenTime,
    supportedLanguages: state.translation.supportedLanguages,
    translationLoading: state.translation.loading,
    language: state.language.language,
    organization: state.organization.organization,
    selectedTheme: state.designs.selectedTheme,
    designs: state.designs.designs,
    loginDesignSetup: state.designs.loginDesignSetup,
    currentRestaurantDesign: state.designs.currentRestaurantDesign,
    getDesignsLoading: state.designs.getDesignsLoading,
    mainDesign: state.designs.mainDesign,
    reference: state.affiliateLinks.reference,
    token: state.auth.token,
    fcmToken: state.notifications.token,
    userPoints: state?.user?.user && state?.user?.user?.LoyalityUsers && state?.user?.user?.LoyalityUsers?.length > 0 ? state?.user?.user?.LoyalityUsers[0]?.totalPoints : 0,
    appSource: state.appSource.appSource,
    getUnreadMessagesLoading: state.messages.getUnreadMessagesLoading,
    getRestaurantBranchModulesLoading: state.restaurants.getRestaurantBranchModulesLoading,
    getFavoritesLoading: state.appSource.appSource === "dine-in"
      ? state.favorites.getDineInFavoritesLoading
      : state.appSource.appSource === "QR"
        ? state.favorites.getQRFavoritesLoading
        : state.appSource.appSource === "pay"
          ? state.favorites.getPayFavoritesLoading
          : state.favorites.getFavoritesLoading,
    menusSelectedRestaurant:
      state.appSource.appSource === "dine-in"
        ? state.products.dineInMenusSelectedRestaurant
        : state.appSource.appSource === "pay"
          ? state.products.payMenusSelectedRestaurant
          : state.appSource.appSource === "QR"
            ? state.products.QRMenusSelectedRestaurant
            : state.products.menusSelectedRestaurant,
    selectedMenu:
      state.appSource.appSource === "dine-in"
        ? state.products.dineInSelectedMenu
        : state.appSource.appSource === "pay"
          ? state.products.paySelectedMenu
          : state.appSource.appSource === "QR"
            ? state.products.QRSelectedMenu
            : state.products.selectedMenu,
    menus:
      state.appSource.appSource === "dine-in"
        ? state.products.dineInMenus
        : state.appSource.appSource === "pay"
          ? state.products.payMenus
          : state.appSource.appSource === "QR"
            ? state.products.QRMenus
            : state.products.menus,
    // products: state.products.products,
    products:
      state.appSource.appSource === "dine-in"
        ? state.products.dineInBranchAllMenusProducts
        : state.appSource.appSource === "pay"
          ? state.products.payBranchAllMenusProducts
          : state.appSource.appSource === "QR"
            ? state.products.QRBranchAllMenusProducts
            : state.products.branchAllMenusProducts,
    dineInSelectedBranch: state.restaurants.dineInSelectedBranch,
    paySelectedBranch: state.restaurants.paySelectedBranch,
    QRSelectedBranch: state.restaurants.QRSelectedBranch,
    deliveryMethod: state.deliveryMethod.deliveryMethod,
    deliverySelectedBranch: state.restaurants.deliverySelectedBranch,
    userSelectedBranch: state.restaurants.userSelectedBranch,
    cartDrafts: state.cart.cartDrafts,
    cartBranch: state.cart.cartBranch,
    cartDeliveryMethod: state.cart.cartDeliveryMethod,
    cart:
      state.appSource.appSource === "dine-in"
        ? state.cart.dineInCart
        : state.appSource.appSource === "pay"
          ? state.cart.payCart
          : state.appSource.appSource === "QR"
            ? state.cart.QRCart
            : state.cart.cart,
    cartPointsData: state.appSource.appSource === "pay"
      ? state.cart.payPointsData
      : state.appSource.appSource === "dine-in"
        ? state.cart.dineInPointsData
        : state.appSource.appSource === "QR"
          ? state.cart.QRPointsData
          : state.cart.pointsData, QRCart: state.cart.QRCart,
    appSelectedRestaurant: state.restaurants.selectedRestaurant,
    selectedRestaurant: state.appSource.appSource === "dine-in"
      ? state.restaurants.dineInSelectedRestaurant
      : state.appSource.appSource === "pay"
        ? state.restaurants.paySelectedRestaurant
        : state.appSource.appSource === "QR"
          ? state.restaurants.QRSelectedRestaurant
          : state.restaurants.selectedRestaurant,
    logo: state.restaurants.selectedRestaurantData?.logo,
    token: state?.auth?.token,
    getRestaurantsLoading: state.restaurants.getRestaurantsLoading,
    selectedRestaurantAvailableBranches: state.restaurants.selectedRestaurantAvailableBranches,
    hasDelivery:
      state.organization.activatedModules?.find((module) => {
        return module?.name === "delivery";
      }) && state.restaurants?.selectedRestaurantDeliveryBranches?.length > 0,
    hasPickup:
      state.organization.activatedModules?.find((module) => {
        return module?.name === "pick-up";
      }) && state.restaurants?.selectedRestaurantPickupBranches?.length > 0,
    hasDinein:
      state.organization.activatedModules?.find((module) => {
        return module?.name === "dine-in";
      }) && state.restaurants?.selectedRestaurantDineinBranches?.length > 0,
    currentRestaurantModeIconsStyle: state.designs.currentRestaurantModeIconsStyle,
    restoBranchHasLoyalty: state.appSource.appSource === "QR"
      ? state.restaurants.QRRestaurantBranchHasLoyalty
      : state.appSource.appSource === "dine-in"
        ? state.restaurants.dineInRestaurantBranchHasLoyalty
        : state.appSource.appSource === "pay"
          ? state.restaurants.payRestaurantBranchHasLoyalty
          : state.restaurants.restaurantBranchHasLoyalty,
    useHasInteracted: state.media.userHasInteracted
  };
};

const mapDispatchToProps = (dispatch) => ({
  getModules: () => {
    dispatch(OrganizationActions.getModules());
  },
  setOrganization: (value, previousValue) => {
    dispatch(OrganizationActions.setOrganization(value, previousValue))
  },
  getTranslation: () => {
    dispatch(TranslationActions.getTranslation());
  },
  setLastAppOpenTime: () => {
    dispatch(DesignsActions.setLastAppOpenTime());
  },
  getDesigns: () => {
    dispatch(DesignsActions.getDesigns());
  },
  setReference: (ref) => {
    dispatch(AffiliateLinksActions.setReference(ref))
  },
  updateCurrentRestaurantThemeData: (ruuid, mode) => {
    dispatch(DesignsActions.updateCurrentRestaurantThemeData(ruuid, mode))
  },
  resetDesigns: () => {
    dispatch(DesignsActions.resetDesigns());
  },
  setSelectedTheme: (value) => {
    dispatch(DesignsActions.setSelectedTheme(value))
  },
  setAppSource: (value) => {
    dispatch(AppSourceActions.setAppSource(value));
  },
  resetAppSource: () => {
    dispatch(AppSourceActions.resetAppSource());
  },
  getMe: () => dispatch(UserActions.getMe()),
  clearAllRestaurantsData: () => dispatch(RestaurantsActions.clearAllRestaurantsData()),
  getRestaurantBranchModules: (restaurantId, branchId, appSource) => {
    dispatch(RestaurantsActions.getRestaurantBranchModules(restaurantId, branchId, appSource)
    )
  },
  setFcmToken: (token) => dispatch(NotificationsActions.setFcmToken(token)),
  registerDevice: (token) => dispatch(NotificationsActions.registerDevice(token)),
  getLoyaltyInfo: (restaurantId, appSource) => {
    dispatch(LoyaltyActions.getLoyaltyInfo(restaurantId, appSource))
  },
  changeDeliveryMethod: (method) =>
    dispatch(DeliveryMethodActions.changeDeliveryMethod(method)),
  clearCart: (appSource) => {
    dispatch(CartActions.clearCart(appSource));
  },
  updateCartDetail: (detail, value) =>
    dispatch(CartActions.updateCartDetail(detail, value)),
  cartDraftUseStart: () =>
    dispatch(CartActions.cartDraftUseStart()),
  cartDraftUseEnd: (ruuid) =>
    dispatch(CartActions.cartDraftUseEnd(ruuid)),
  setGotUsedMenus: (value) =>
    dispatch(CartActions.setGotUsedMenus(value)),
  setUserCurrentLocation: (lat, lng, location, addressDetails) =>
    dispatch(
      AddressesActions.setUserCurrentLocation(
        lat,
        lng,
        location,
        addressDetails
      )
    ),
  resetUserCurrentLocation: () =>
    dispatch(AddressesActions.resetUserCurrentLocation()),
  setHandledCurrentLocation: (value) => {
    dispatch(AddressesActions.setHandledCurrentLocation(value));
  },
  clearFilters: (appSource) =>
    dispatch(ProductsActions.clearFilters(appSource)),
  resetMenus: (appSource) => dispatch(ProductsActions.resetMenus(appSource)),
  getAllRestaurants: (key) => dispatch(RestaurantsActions.getAllRestaurants(key)),
  resetNearestDeliveryBranch: () =>
    dispatch(RestaurantsActions.resetNearestDeliveryBranch()),
  setNoSelectedLocationMessagePoppedUp: (value) => {
    dispatch(AddressesActions.setNoSelectedLocationMessagePoppedUp(value));
  },
  setFarFromSelectedLocationMessageConfirmed: (value) => {
    dispatch(
      AddressesActions.setFarFromSelectedLocationMessageConfirmed(value)
    );
  },
  setShowSelectedLocationAnimation: (value) => {
    dispatch(AddressesActions.setShowSelectedLocationAnimation(value));
  },
  clearAllDiscountsData: () => dispatch(DiscountsActions.clearAllDiscountsData()),
  clearAllPromotionsData: () => dispatch(PromotionsActions.clearAllPromotionsData()),
  addToCart: (cartItem, appSource) => {
    dispatch(CartActions.addToCart(cartItem, appSource));
  },
  setCartDetail: (restaurant, branch, deliveryMethod) => {
    dispatch(CartActions.setCartDetails(restaurant, branch, deliveryMethod));
  },
  updatePointsUsed: (additionTime, value, appSource) => {
    dispatch(CartActions.updatePointsUsed(additionTime, value, appSource));
  },
  removePointsUsed: (additionTime, appSource) => {
    dispatch(CartActions.removePointsUsed(additionTime, appSource));
  },
  resetPointsData: (appSource) => {
    dispatch(CartActions.resetPointsData(appSource));
  },
  setCurrentAddress: (addressId) =>
    dispatch(AddressesActions.setCurrentAddress(addressId)),
  setUserSelectedBranch: (branchId) => {
    dispatch(RestaurantsActions.setUserSelectedBranch(branchId));
  },
  getFavorites: (restaurantId, appSource) => {
    dispatch(FavoritesActions.getFavorites(restaurantId, appSource));
  },
  setUserHasInteracted: (value) => {
    dispatch(MediaActions.setUserHasInteracted(value));
  },
  clearAllMediaData: () =>
    dispatch(MediaActions.clearAllMediaData()),
  getUnreadMessages: (restaurantId) => {
    dispatch(MessagesActions.getUnreadMessages(restaurantId))
  },
  getMessages: (ruuid, buuid, pageNumber, pageLimit, appSource) => {
    dispatch(MessagesActions.getMessages(ruuid, buuid, pageNumber, pageLimit, appSource));
  },
  clearAllMessagesData: () => {
    dispatch(MessagesActions.clearAllMessagesData())
  },
  clearAllFavoritesData: () => {
    dispatch(FavoritesActions.clearAllFavoritesData())
  },
  clearLoading: () => {
    dispatch(GlobalActions.clearLoading())
  },
  clearAllData: () => {
    dispatch(GlobalActions.clearAllData())
  },
  clearAllPopularItemsData: () => dispatch(PopularItemsActions.clearAllPopularItemsData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
