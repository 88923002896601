import { getPopularItems } from '../../config/config';
import { notify } from '../../utils/notify';

export const GET_POPULAR_ITEMS_REQUEST = 'GET_POPULAR_ITEMS_REQUEST';
export const GET_POPULAR_ITEMS_SUCCESS = 'GET_POPULAR_ITEMS_SUCCESS';
export const GET_POPULAR_ITEMS_ERROR = 'GET_POPULAR_ITEMS_ERROR';
export const SET_GOT_POPULAR_ITEMS_THE_FIRST_TIME = 'SET_GOT_POPULAR_ITEMS_THE_FIRST_TIME';
export const CLEAR_ALL_POPULAR_ITEMS_DATA = 'CLEAR_ALL_POPULAR_ITEMS';

export const actions = {
  getPopularItems: (restaurantId, appSource) => async (dispatch) => {
    dispatch({ 
      type: GET_POPULAR_ITEMS_REQUEST,
      payload: {
        appSource: appSource
      } 
    });
    try {
    const response = await getPopularItems(restaurantId);
      if (response?.status === 200) {
       // setTimeout(() => {
        dispatch({
          type: GET_POPULAR_ITEMS_SUCCESS,
          payload: {
            popularItems: response?.data?.products,
            appSource: appSource
          },
        });
        dispatch({
          type: SET_GOT_POPULAR_ITEMS_THE_FIRST_TIME,
          payload: {
            value: true,
            appSource: appSource,
          }
        })
        // }, 5000);
      } else {
        notify.error(response?.data?.message || 'Get Popular Items error');
        dispatch({
          type: GET_POPULAR_ITEMS_ERROR,
          payload: {
            appSource: appSource
          }
        });
      }
   } catch (error) {
      dispatch({
      type: GET_POPULAR_ITEMS_ERROR,
      payload: {
        appSource: appSource
      }
   });
  }
},
  clearAllPopularItemsData: () => (dispatch) => {
    dispatch({
      type: CLEAR_ALL_POPULAR_ITEMS_DATA
    })
  }
};
