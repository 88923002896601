import { getTranslation } from '../../config/config';
import { notify } from '../../utils/notify';
import { SET_LANGUAGE } from './LanguageActions';

export const GET_TRANSLATION_REQUEST = 'GET_TRANSLATION_REQUEST';
export const GET_TRANSLATION_SUCCESS = 'GET_TRANSLATION_SUCCESS';
export const GET_TRANSLATION_ERROR = 'GET_TRANSLATION_ERROR';

export const actions = {
  getTranslation: () => async (dispatch, getState) => {
    dispatch({ type: GET_TRANSLATION_REQUEST });
    
    try {
      const res = await getTranslation();
      if (res?.status === 200) {
        const currentLanguage = getState().language.language;

        const supportedLanguages = res?.data?.supportedLanguages;
        const translation = res?.data?.translations[0]?.languages;

        const defaultLanguage = supportedLanguages?.find((lang) => {
          return lang?.isDefault
        })

        if (!currentLanguage || (currentLanguage && !supportedLanguages?.find((lang) => {
          return lang?.name?.toLowerCase() == currentLanguage?.toLowerCase()
        }))) {
          const value = defaultLanguage ? defaultLanguage?.name : supportedLanguages?.length > 0 ? supportedLanguages[0]?.name : "";

          dispatch({
            type: SET_LANGUAGE,
            payload: value
          })
        } 

        dispatch({
          type: GET_TRANSLATION_SUCCESS,
          payload: {
            supportedLanguages: supportedLanguages,
            translation: translation
          },
        });
      } else {
        const errorMessage = res?.data?.message || 'Get Translation error';
        notify.error(errorMessage);
        dispatch({ type: GET_TRANSLATION_ERROR });
      }
    } catch (error) {
      notify.error('Get Translation error');
      dispatch({ type: GET_TRANSLATION_ERROR });
    }
  },
};
