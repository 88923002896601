import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions as RestaurantsActions } from "../../redux/actions/RestaurantsActions";
import { actions as CartActions } from "../../redux/actions/CartActions";
import { actions as ProductsActions } from "../../redux/actions/ProductsActions";
import { getDictionary, getField } from "../../utils/translation";
import OptionsMessagePopup from "../OptionsMessagePopup/OptionsMessagePopup";

const CartOptionsPopup = ({
  translation,
  language,
  selectedRestaurant,
  currentAddress,
  deliveryMethod,
  to,
  open,
  setOpen,
  appSource,
  cart,
  clearCart,
  cancel,
  addCartDraft,
  removeCartDraft,
  resetMenus,
  setSelectedMenu,
  setSelectedRestaurant,
  setGotUsedMenus,
  clearFilters
}) => {
  const dictionary = getDictionary(translation, "home-top-bar");
  const navigate = useNavigate();
  return (
    <OptionsMessagePopup
      open={open}
      setOpen={setOpen}
      messageTitle={getField(
        dictionary,
        "cart-save-or-discard-title",
        language
      )}
      messageText={getField(dictionary, "cart-save-or-discard-text", language)}
      optionOneText={getField(
        dictionary,
        "cart-save-or-discard-save",
        language
      )}
      optionTwoText={getField(
        dictionary,
        "cart-save-or-discard-discard",
        language
      )}
      cancelText={getField(dictionary, "cart-save-or-discard-cancel", language)}
      cancel={cancel}
       optionOneCb={() =>
          //should save the cart as draft here, then clear the cart
          {
            addCartDraft(selectedRestaurant, currentAddress, deliveryMethod);
            clearCart(appSource);
            setGotUsedMenus(false);
            setSelectedRestaurant(-1);
            setSelectedMenu(null, appSource);
            clearFilters();
            resetMenus(appSource);

            navigate(to ? to : "/");
          }
        }
        optionTwoCb={() =>
          //clear the cart and discard
          {
            removeCartDraft(selectedRestaurant);
            clearCart(appSource);
            setGotUsedMenus(false);
            setSelectedRestaurant(-1);
            setSelectedMenu(null, appSource);
            clearFilters();
            resetMenus(appSource);
            navigate(to ? to : "/");
          }
        }
      // optionOneCb={() =>
      //   //should save the cart as draft here, then clear the cart
      //   {
      //     clearCart(appSource);
      //     //  setSelectedRestaurant(newSelectedRestaurant);
      //     navigate(to ? to : "/");
      //   }
      // }
      // optionTwoCb={() =>
      //   //clear the cart and discard
      //   {
      //     clearCart(appSource);
      //     //  setSelectedRestaurant(newSelectedRestaurant);
      //     navigate(to ? to : "/");
      //   }
      // }
    />
  );
};

const mapStateToProps = (state) => ({
  translation: state.translation.translation,
  language: state.language.language,
  appSource: state.appSource.appSource,
  deliveryMethod: state.deliveryMethod.deliveryMethod,
  cart: state.cart.cart,
    selectedRestaurant:
    state.appSource.appSource === "dine-in"
      ? state.restaurants.dineInSelectedRestaurant
      : state.appSource.appSource === "pay"
      ? state.restaurants.paySelectedRestaurant
      : state.appSource.appSource === "QR"
      ? state.restaurants.QRSelectedRestaurant
      : state.restaurants.selectedRestaurant,
  currentAddress: state.addresses.currentAddress,

});

const mapDispatchToProps = (dispatch) => ({
  setSelectedRestaurant: (id) => {
    dispatch(RestaurantsActions.setSelectedRestaurant(id));
  },
  clearCart: (appSource) => {
    dispatch(CartActions.clearCart(appSource));
  },
  addCartDraft: (ruuid, currentAddress, deliveryMethod) => {
    dispatch(CartActions.addCartDraft(ruuid, currentAddress, deliveryMethod));
  },
  removeCartDraft: (ruuid) => 
    dispatch(CartActions.removeCartDraft(ruuid)),
  setGotUsedMenus: (value) => 
    dispatch(CartActions.setGotUsedMenus(value)),
  resetMenus: (appSource) => dispatch(ProductsActions.resetMenus(appSource)),
  setSelectedMenu: (menuId, appSource) =>
    dispatch(ProductsActions.setSelectedMenu(menuId, appSource)),
  clearFilters: () => dispatch(ProductsActions.clearFilters())
});

export default connect(mapStateToProps, mapDispatchToProps)(CartOptionsPopup);
