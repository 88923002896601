import {
  SET_MEDIA_RESTAURANT,
  GET_MEDIA_REQUEST,
  GET_MEDIA_SUCCESS,
  GET_MEDIA_ERROR,
  ADD_MEDIA_INTERACTION_REQUEST,
  ADD_MEDIA_INTERACTION_SUCCESS,
  ADD_MEDIA_INTERACTION_ERROR,
  SET_USER_HAS_INTERACTED,
  CLEAR_ALL_MEDIA_DATA,
} from "../actions/MediaActions";

const initialState = {
  loading: false,
  mediaRestaurant: "",
  media: [],
  totalRecords: 0,
  totalPages: 0,
  currentPage: 0,
  //DINE IN
  dineInMediaRestaurant: "",
  dineInMedia: [],
  dineInTotalRecords: 0,
  dineInTotalPages: 0,
  dineInCurrentPage: 0,
  //PAY
  payMediaRestaurant: "",
  payMedia: [],
  payTotalRecords: 0,
  payTotalPages: 0,
  payCurrentPage: 0,
  //QR
  QRMediaRestaurant: "",
  QRMedia: [],
  QRTotalRecords: 0,
  QRTotalPages: 0,
  QRCurrentPage: 0,

  getMediaLoading: false,
  addInteractionLoading: false,
  addLikeLoading: false,
  addShareLoading: false,
  addSavedLoading: false,

  userHasInteracted: false
};

function media(state = initialState, action) {
  const appSource = action?.payload?.appSource;
  const mediaRestaurantState =
    appSource === "dine-in"
      ? "dineInMediaRestaurant"
      : appSource === "pay"
        ? "payMediaRestaurant"
        : appSource === "QR"
          ? "QRMediaRestaurant"
          : "mediaRestaurant";
  const mediaState =
    appSource === "dine-in"
      ? "dineInMedia"
      : appSource === "pay"
        ? "payMedia"
        : appSource === "QR"
          ? "QRMedia"
          : "media";
  const totalRecordsState =
    appSource === "dine-in"
      ? "dineInTotalRecords"
      : appSource === "pay"
        ? "payTotalRecords"
        : appSource === "QR"
          ? "QRTotalRecords"
          : "totalRecords";
  const totalPagesState =
    appSource === "dine-in"
      ? "dineInTotalPages"
      : appSource === "pay"
        ? "payTotalPages"
        : appSource === "QR"
          ? "QRTotalPages"
          : "totalPages";
  const currentPageState =
    appSource === "dine-in"
      ? "dineInCurrentPage"
      : appSource === "pay"
        ? "payCurrentPage"
        : appSource === "QR"
          ? "QRCurrentPage"
          : "currentPage";
  switch (action.type) {
    case SET_MEDIA_RESTAURANT:
      return Object.assign({}, state, {
        [mediaRestaurantState]: action?.payload?.uuid
      });
    case GET_MEDIA_REQUEST:
      return Object.assign({}, state, {
        getMediaLoading: true,
      });
    case GET_MEDIA_SUCCESS:
      return Object.assign({}, state, {
        getMediaLoading: false,
        [mediaState]:
          action?.payload?.currentPage === 1
            ? action?.payload?.media
            : [...state.media, ...action?.payload?.media],
        [totalRecordsState]: action?.payload?.totalRecords,
        [totalPagesState]: action?.payload?.totalPages,
        [currentPageState]: action?.payload?.currentPage,
      });
    //   case GET_MEDIA_SUCCESS:
    // const newMedia = action?.payload?.media.filter((mediaItem) => {
    //   return !state.media.find((item) => item.uuid === mediaItem.uuid);
    // });
    // console.log("new set",action?.payload?.media);
    // console.log("new media", newMedia);
    // return Object.assign({}, state, {
    //   getMediaLoading: false,
    //   media:
    //     action?.payload?.currentPage === 1
    //       ? newMedia
    //       : [...state.media, ...newMedia],
    //   totalRecords: action?.payload?.totalRecords,
    //   totalPages: action?.payload?.totalPages,
    //   currentPage: action?.payload?.currentPage,
    // });

    case GET_MEDIA_ERROR:
      return Object.assign({}, state, {
        getMediaLoading: false,
      });
    case ADD_MEDIA_INTERACTION_REQUEST:
      const requestInteraction = Object.keys(action?.payload?.data)[0];
      let requestLoadingInteraction;
      switch (requestInteraction) {
        case "like":
          requestLoadingInteraction = "addLikeLoading";
          break;
        case "saved":
          requestLoadingInteraction = "addSavedLoading";
          break;
        case "share":
          requestLoadingInteraction = "addShareLoading";
          break;
      }
      return Object.assign({}, state, {
        [requestLoadingInteraction]: true,
        addInteractionLoading: true,
        [mediaState]: state[mediaState]?.map((post) => {
          if (post.uuid === action.payload.mediaId) {
            const mediaInteractions = [...post.MediaInteractions];
            const interactionData = action.payload.data;
            const firstInteraction = mediaInteractions[0];
            const newValue =
              interactionData[requestInteraction] === "true" ? true : false;

            if (firstInteraction) {
              if (requestInteraction === "like") {
                firstInteraction.like = newValue;
                post.totalLikes += newValue ? 1 : -1;
              } else if (requestInteraction === "share") {
                firstInteraction.share = newValue;
                post.totalShare += newValue ? 1 : -1;
              } else if (requestInteraction === "saved") {
                firstInteraction.saved = newValue;
                post.totalSave += newValue ? 1 : -1;
              }
            } else {
              // No MediaInteractions exist, so create a new one
              const newInteraction = {};
              if (requestInteraction === "like") {
                newInteraction.like = newValue;
                post.totalLikes += newValue ? 1 : -1;
              } else if (requestInteraction === "share") {
                newInteraction.share = newValue;
                post.totalShare += newValue ? 1 : -1;
              } else if (requestInteraction === "saved") {
                newInteraction.saved = newValue;
                post.totalSave += newValue ? 1 : -1;
              }
              mediaInteractions.unshift(newInteraction);
            }

            return Object.assign({}, post, {
              MediaInteractions: mediaInteractions,
            });
          } else {
            return post;
          }
        }),
      });
    case ADD_MEDIA_INTERACTION_SUCCESS:
      const successInteraction = Object.keys(action?.payload?.data)[0];
      let successLoadingInteraction;
      switch (successInteraction) {
        case "like":
          successLoadingInteraction = "addLikeLoading";
          break;
        case "saved":
          successLoadingInteraction = "addSavedLoading";
          break;
        case "share":
          successLoadingInteraction = "addShareLoading";
          break;
      }
      return Object.assign({}, state, {
        addInteractionLoading: false,
        [successLoadingInteraction]: false,
      });
    case ADD_MEDIA_INTERACTION_ERROR:
      const errorInteraction = Object.keys(action?.payload?.data)[0];
      let errorLoadingInteraction;
      switch (errorInteraction) {
        case "like":
          errorLoadingInteraction = "addLikeLoading";
          break;
        case "saved":
          errorLoadingInteraction = "addSavedLoading";
          break;
        case "share":
          errorLoadingInteraction = "addShareLoading";
          break;
      }

      // Revert changes made in the request
      const mediaCopy = [...state[mediaState]];
      mediaCopy.forEach((post) => {
        if (post.uuid === action.payload.mediaId) {
          const mediaInteractions = [...post.MediaInteractions];
          const interactionData = action.payload.data;
          const firstInteraction = mediaInteractions[0];
          const newValue =
            interactionData[errorInteraction] === "true" ? true : false;

          if (firstInteraction) {
            if (errorInteraction === "like") {
              firstInteraction.like = !newValue;
              post.totalLikes -= newValue ? 1 : -1;
            } else if (errorInteraction === "share") {
              firstInteraction.share = !newValue;
              post.totalShare -= newValue ? 1 : -1;
            } else if (errorInteraction === "saved") {
              firstInteraction.saved = !newValue;
              post.totalSave -= newValue ? 1 : -1;
            }
          } else {
            // No MediaInteractions exist, so nothing to reverse
          }
          post.MediaInteractions = mediaInteractions;
        }
      });

      return Object.assign({}, state, {
        addInteractionLoading: false,
        [errorLoadingInteraction]: false,
        [mediaState]: mediaCopy,
      });

    // case ADD_MEDIA_INTERACTION_ERROR:
    //    const errorInteraction = Object.keys(action?.payload?.data)[0];
    //   let errorLoadingInteraction;
    //   switch (errorInteraction) {
    //     case "like":
    //       errorLoadingInteraction = "addLikeLoading";
    //       break;
    //     case "saved":
    //       errorLoadingInteraction = "addSavedLoading";
    //       break;
    //     case "share":
    //       errorLoadingInteraction = "addShareLoading";
    //       break;
    //   }
    //   //reverse changes
    case SET_USER_HAS_INTERACTED:
      return Object.assign({}, state, {
        userHasInteracted: action?.payload?.value
      });
      
    case CLEAR_ALL_MEDIA_DATA:
      return Object.assign({}, state, {
        ...initialState,
        userHasInteracted: state.userHasInteracted
      });
    default:
      return state;
  }
}

export default media;
